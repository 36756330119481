import React from 'react';
import { CssBaseline } from '@mui/material';
import Navbar from './components/Navbar';
import Home from './components/Home';
import WorkSection from './components/WorkSection';
// import Contact from './components/Contact';
import Footer from './components/Footer';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { GlobalStyle } from './GlobalStyle';

const theme = createTheme({
  palette: {
    primary: {
      main: '#5a2e2e', // Cor personalizada
    },
    secondary: {
      main: '#3e5a2e', // Vermelho próximo
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStyle />
      {/* <Navbar /> */}
      <Home />
      {/* <WorkSection />  */}

      {/* <Contact /> */}
      <Footer />
    </ThemeProvider>
  );
};

export default App;