import React from 'react';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

import imagem from '../assets/fog-3622519_1920.jpg';

const BackgroundBox = styled(Box)`
  background-image: url('${imagem}');
  background-size: cover;
  background-position: center;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Home: React.FC = () => {
  return (
    <BackgroundBox id="home">
      <Overlay />
      <Box sx={{ position: 'relative', zIndex: 1 }}>
        <Typography variant="h2" component="h1" gutterBottom>
          Programador
        </Typography>
        <Typography variant="h5" component="p">
          Web, Mobile, Front-end, Back-end, Desktop
        </Typography>
      </Box>
    </BackgroundBox>
  );
};

export default Home;