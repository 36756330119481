import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import styled from 'styled-components';

const FooterBox = styled(Box)`
  background-color: #5a2e2e;
  color: white;
  padding: 1rem 0;
  text-align: center;
`;

const Footer: React.FC = () => {
  return (
    <FooterBox as="footer">
      <Typography variant="body2">
        &copy; 2024 <strong>TALISSON BRUNO</strong>. Todos os direitos reservados.
      </Typography>
      <Typography variant="body2">
        Projetado por{' '}
        <Link href="https://talissonbr.com.br" color="inherit" underline="none">
          TALISSON
        </Link>
      </Typography>
    </FooterBox>
  );
};

export default Footer;